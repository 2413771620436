import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, Teleport as _Teleport } from "vue"

const _hoisted_1 = { class: "editing-voc-list" }
const _hoisted_2 = {
  class: "app-h-s18-h24-w7-c333",
  style: {"margin-top":"20px","margin-bottom":"5px"}
}
const _hoisted_3 = { class: "editing-voc-list-values" }
const _hoisted_4 = { class: "editing-voc-list" }
const _hoisted_5 = {
  key: 1,
  class: "editing-voc-list-values"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CircleLoader = _resolveComponent("CircleLoader")!
  const _component_AppRouterLink = _resolveComponent("AppRouterLink")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.list === null)
        ? (_openBlock(), _createBlock(_component_CircleLoader, { key: 0 }))
        : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.groupListKeysView, (key) => {
            return (_openBlock(), _createElementBlock(_Fragment, {
              key: _ctx.groupList[key].caption
            }, [
              _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.groupList[key].caption), 1),
              _createElementVNode("div", _hoisted_3, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.groupList[key].values, (value) => {
                  return (_openBlock(), _createBlock(_component_AppRouterLink, {
                    class: "app-label-s16-h24-w5-c333 editing-voc-list-values-value",
                    key: value.voc_name,
                    to: `/editing-voc/${value.voc_type}`,
                    mark: _ctx.mark,
                    onIsActiveChanged: _ctx.onIsActiveChanged
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(value.voc_name || value.voc_type), 1)
                    ]),
                    _: 2
                  }, 1032, ["to", "mark", "onIsActiveChanged"]))
                }), 128))
              ])
            ], 64))
          }), 128))
    ]),
    (_ctx.groupList.settings)
      ? (_openBlock(), _createBlock(_Teleport, {
          key: 0,
          to: '#' + _ctx.EDITING_VOC_LIST_SETTINGS_ID
        }, [
          _createElementVNode("div", _hoisted_4, [
            (_ctx.list === null)
              ? (_openBlock(), _createBlock(_component_CircleLoader, { key: 0 }))
              : (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.groupList.settings.values, (value) => {
                    return (_openBlock(), _createBlock(_component_AppRouterLink, {
                      class: "app-label-s16-h24-w5-c333 editing-voc-list-values-value",
                      key: value.voc_name,
                      to: `/editing-voc/${value.voc_type}`,
                      mark: _ctx.mark,
                      onIsActiveChanged: _ctx.onIsActiveChanged
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(value.voc_name || value.voc_type), 1)
                      ]),
                      _: 2
                    }, 1032, ["to", "mark", "onIsActiveChanged"]))
                  }), 128))
                ]))
          ])
        ], 8, ["to"]))
      : _createCommentVNode("", true)
  ], 64))
}