
import { defineComponent } from "vue";
import {
  CircleLoader,
  Dictionary,
  NotificationsService,
} from "table";
import { ApiHelper } from "table";
import AppApiService from "@/services/AppApiService/AppApiService";
import { VocDto } from "table/dist/services/Api/types/VocRepositoryDto";
import AppRouterLink from "@/components/AppRouterLink.vue";

export const EDITING_VOC_LIST_SETTINGS_ID = "editing-voc-list-settings";

export default defineComponent({
  name: "MainMenuVocList",
  props: {
    mark: String,
  },
  emits: ["is-active-changed"],
  components: {
    CircleLoader,
    AppRouterLink,
  },
  setup() {
    return {
      EDITING_VOC_LIST_SETTINGS_ID,
    };
  },
  data() {
    return {
      list: null as null | VocDto[],
      groupList: {} as Dictionary<{
        caption: string;
        values: VocDto[];
      }>,
    };
  },
  computed: {
    groupListKeysView() {
      return ["custom", "system"];
    },
  },
  methods: {
    onIsActiveChanged(props: any) {
      this.$emit("is-active-changed", props);
    },
  },
  async created() {
    try {
      this.list = (await AppApiService.getVocsTypesList()).json.result;
      this.groupList.custom = {
        caption: "Пользовательские",
        values: this.list.filter((x) => x.group === "custom"),
      };
      this.groupList.system = {
        caption: "Системные",
        values: this.list.filter((x) => x.group === "system"),
      };
      this.groupList.settings = {
        caption: "Настройки",
        values: this.list.filter((x) => x.group === "settings"),
      };
    } catch (e) {
      const errorText = await ApiHelper.getErrorMessage(e);
      NotificationsService.send({
        type: "error",
        title: "Произошла ошибка при загрузке",
        text: errorText,
      });
    }
  },
});
